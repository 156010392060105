<div class="menu">
	<ion-grid>
		<ion-row>
			<ion-col>
				<ion-button
					class="button-transparent"
					fill="clear"
					[routerLink]="['/therapist/home']"
					routerDirection="root"
					(click)="cambiarDisplay1()"
					><img alt="Icono de home" src="../../../assets/Menu_ic_Home.svg"
				/></ion-button>
			</ion-col>
			<ion-col>
				<ion-button
					class="button-transparent"
					fill="clear"
					[routerLink]="['/therapist/contents']"
					routerDirection="root"
					(click)="cambiarDisplay2()"
					><img alt="Icono de contents" src="../../../assets/Menu_ic_Esp terapeutico.svg"
				/></ion-button>
			</ion-col>
			<ion-col>
				<ion-button
					class="button-transparent"
					fill="clear"
					[routerLink]="['/therapist/hours']"
					routerDirection="root"
					(click)="cambiarDisplay3()"
					><ion-icon alt="Icono de therapist hours" src="../../../assets/time.svg"></ion-icon
				></ion-button>
			</ion-col>
			<ion-col>
				<ion-button
					class="button-transparent"
					fill="clear"
					routerDirection="root"
					(click)="cambiarDisplay4()"
					><ion-icon alt="Icono de chat" src="../../../assets/chatbubble.svg"></ion-icon
				></ion-button>
			</ion-col>
		</ion-row>
		<ion-row id="identificadores">
			<ion-col>
				<ion-icon id="Home" class="selector" src="../../../assets/Menu_ic_Selector.svg"></ion-icon>
			</ion-col>
			<ion-col>
				<ion-icon id="Contents" class="selector" src="../../../assets/Menu_ic_Selector.svg"></ion-icon>
			</ion-col>
			<ion-col>
				<ion-icon id="Hours" class="selector" src="../../../assets/Menu_ic_Selector.svg"></ion-icon>
			</ion-col>
			<ion-col>
				<ion-icon id="Chat" class="selector" src="../../../assets/Menu_ic_Selector.svg"></ion-icon>
			</ion-col>
		</ion-row>
	</ion-grid>
</div>
